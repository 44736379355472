<template>
  <Layout>
    <div class="app-prize-list">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="add" type="primary">
            <a-icon type="file-add" /> 新建抽奖活动
          </a-button>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table :columns="columns" :data-source="list" :pagination="false">
          <span slot="startTime" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="endTime" slot-scope="time">{{ time | formatDate }}</span>
          <span slot="action" slot-scope="_, record">
            <router-link :to="{ name: 'PrizeEdit', query: { id: record.id } }">
              编辑
            </router-link>
            <a-divider type="vertical" />
            <a @click="share(record.id)">分享</a>
            <a-divider type="vertical" />
            <a style="color:#f5222d" @click="del(record.id)">删除</a>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 分享二维码 -->
    <a-modal
      :destroyOnClose="true"
      :footer="null"
      :visible="shareDialogVisible"
      @cancel="shareDialogVisible = false"
      class="share-dialog-wrap"
    >
      <div class="title">
        右键保存小程序码即可分享
      </div>
      <img :src="shareInfo.qrcode" class="qrcode" />
      <div class="row">
        <span class="label">AppId：</span>
        <a-input disabled :value="store.id" />
        <a-button @click="copyText(store.id)">复制</a-button>
      </div>
      <div class="row">
        <span class="label">路径：</span>
        <a-input disabled :value="shareInfo.path" />
        <a-button @click="copyText(shareInfo.path)">复制</a-button>
      </div>
      <div class="row">
        <a-icon type="info-circle" />
        提示：请先装修首页并发布，否则会显示「页面不存在」的错误
      </div>
    </a-modal>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";

const PrizeSvc = require("@/service/prize");

import wxacodeMixin from "@/mixins/wxacode";

const columns = [
  {
    title: "活动名称",
    dataIndex: "title",
    key: "title",
    width: 200
  },
  {
    title: "奖品",
    dataIndex: "prizeText",
    key: "prizeText"
  },
  {
    title: "参与次数",
    dataIndex: "numText",
    key: "numText",
    width: 150
  },
  {
    title: "开始时间",
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    width: 160,
    scopedSlots: { customRender: "startTime" }
  },

  {
    title: "结束时间",
    align: "center",
    dataIndex: "endTime",
    key: "endTime",
    width: 160,
    scopedSlots: { customRender: "endTime" }
  },

  {
    title: "操作",
    key: "action",
    width: 160,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "PrizeList",

  data() {
    return {
      columns,

      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false
    };
  },

  computed: {
    ...mapState({
      store: state => state.store
    })
  },
  mixins: [wxacodeMixin],

  methods: {
    add() {
      this.$router.push({ name: "PrizeEdit" });
    },

    async del(id) {
      this.$confirm({
        title: "操作提示",
        content: "确认删除此活动吗？",

        onOk: () => {
          this._delete(id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await PrizeSvc.del(id);

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    async share(id) {
      const path = `plugin-private://wxd14f33852509c129/pages/prize/index?id=${id}`;
      await this.sharePath(path);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const data = await PrizeSvc.list({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-prize-list {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
  }
  .table-wrap {
    background: #fff;
  }
}
</style>
